import { useState, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { MDInput, MDButton, MDBox, MDTypography } from "components";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { DevTool } from "@hookform/devtools";

function MDDialog({ openDialog, setOpenDialog, newRole, setNewRole, extendRole, roleIds }) {
  const { t } = useTranslation();
  const [roleIdUniqueError, setRoleIdUniqueError] = useState(false);

  const { control, formState, watch, setValue, reset } = useForm({ mode: "onChange" });
  const { errors } = formState;
  const roleName = watch("roleName");

  const handleClose = () => {
    setValue("roleName", "");
    setValue("roleId", "");
    setOpenDialog(false);
  };

  useEffect(() => {
    if (roleName) {
      const newId = roleName
        .replace(/đ/g, "d")
        .replace(/Đ/g, "D")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^\w\s]|_/g, "")
        .toLowerCase()
        .replace(/\b\w/g, (c) => c.toUpperCase())
        .replace(/\s/g, "");
      setRoleIdUniqueError(roleIds.includes(newId));
      setValue("roleId", newId, { shouldDirty: true, shouldTouch: true });
      setNewRole({ ...newRole, roleId: newId, roleName });
    } else {
      setValue("roleId", null);
    }
  }, [roleName]);

  const extendRoleAction = () => {
    extendRole();
    handleClose();
  };

  useEffect(() => {
    reset();
    setRoleIdUniqueError(false);
  }, [openDialog]);
  return (
    <Dialog open={openDialog} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{t("mapping.extenddialogtitle")}</DialogTitle>
      <DialogContent>
        <MDBox mb={2}>
          <Controller
            name="roleId"
            control={control}
            rules={{
              required: t("mapping.rolenamerequired"),
              validate: (value) => (roleIds.includes(value) ? t("mapping.roleidexists") : true),
            }}
            render={({ field }) => (
              <MDInput
                variant="standard"
                id="roleId"
                type="text"
                label={t("mapping.roleid")}
                fullWidth
                disabled
                InputLabelProps={{ shrink: true }}
                value={field.value || ""}
                className="extend-role-input"
              />
            )}
            defaultValue=""
          />
          <MDBox sx={{ height: 0 }}>
            <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
              {roleIdUniqueError && t("mapping.roleiduniqueerror")}
            </MDTypography>
          </MDBox>{" "}
        </MDBox>
        <MDBox mb={2}>
          <Controller
            name="roleName"
            control={control}
            rules={{
              required: t("mapping.rolenamerequired"),
              maxLength: {
                value: 250,
                message: t("mapping.rolenamemaxerror"),
              },
            }}
            render={({ field }) => (
              <MDInput
                variant="standard"
                id="roleName"
                type="text"
                label={t("mapping.rolename")}
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={field.value.trimStart() || ""}
                onChange={(event) => field.onChange(event.target.value)}
                onBlur={field.onBlur}
                className="extend-role-input"
              />
            )}
            defaultValue=""
          />
          <MDBox sx={{ height: 0 }}>
            <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
              {errors.roleName && t(errors.roleName.message)}
            </MDTypography>
          </MDBox>{" "}
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={handleClose} variant="gradient" color="dark">
          {t("mapping.cancel")}
        </MDButton>
        <MDButton
          onClick={extendRoleAction}
          variant="gradient"
          color="dark"
          disabled={!formState.isValid}
        >
          {t("mapping.create")}
        </MDButton>
      </DialogActions>
      <DevTool control={control} />
    </Dialog>
  );
}
export default MDDialog;
