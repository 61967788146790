import React, { useMemo } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth, useAuthorizationChangedDialog } from "helper";
import HTTPClientContext from "context/httpClientContext";
import { baseURL } from "config";

const getUrl = (path) => `${baseURL}/${path}`;

const getLanguageCode = () => {
  switch (localStorage.getItem("language")) {
    case "en-US":
      return "en";
    case "hr":
      return "hr";
    default:
      return "en";
  }
};

const getHeaders = (headers = {}) => ({
  "Content-Type": "application/json",
  "Error-Language-Code": getLanguageCode(),
  ...headers,
});

function HTTPClientProvider({ children }) {
  const { user, removeUserData } = useAuth();
  const navigate = useNavigate();
  const { showAuthorizationChangedDialog } = useAuthorizationChangedDialog();

  const handleUnauthorized = () => {
    removeUserData();
    if (process.env.NODE_ENV === "development") navigate("/");
    else window.location = "https://iam-fe-test.xbrlpoint.com/?destinationRef=xbrl";
  };

  const get = async (path, cancelToken) => {
    try {
      return await axios.get(getUrl(path), {
        headers: getHeaders({
          actionPermissions: user?.actionPermissions,
          clientId: user?.clientId,
          userId: user?.id,
        }),
        withCredentials: true,
        cancelToken,
      });
    } catch (error) {
      if (error?.response?.status === 401) handleUnauthorized();
      else if (error?.response?.status === 416) showAuthorizationChangedDialog();
      throw error;
    }
  };

  const post = async (path, body, config = {}) => {
    try {
      return await axios.post(getUrl(path), body, {
        headers: getHeaders({
          actionPermissions: user?.actionPermissions,
          clientId: user?.clientId,
          userId: user?.id,
        }),
        withCredentials: true,
        ...config,
      });
    } catch (error) {
      if (error?.response?.status === 401) handleUnauthorized();
      else if (error?.response?.status === 416) showAuthorizationChangedDialog();
      throw error;
    }
  };

  const put = async (path, body) => {
    try {
      return await axios.put(getUrl(path), body, {
        headers: getHeaders({
          actionPermissions: user?.actionPermissions,
          clientId: user?.clientId,
          userId: user?.id,
        }),
        withCredentials: true,
      });
    } catch (error) {
      if (error?.response?.status === 401) handleUnauthorized();
      else if (error?.response?.status === 416) showAuthorizationChangedDialog();
      throw error;
    }
  };

  const del = async (path, data) => {
    try {
      return await axios.delete(getUrl(path), {
        data,
        headers: getHeaders({
          actionPermissions: user?.actionPermissions,
          clientId: user?.clientId,
          userId: user?.id,
        }),
        withCredentials: true,
      });
    } catch (error) {
      if (error?.response?.status === 401) handleUnauthorized();
      else if (error?.response?.status === 416) showAuthorizationChangedDialog();
      throw error;
    }
  };

  const postDoc = async (path, file, config, cancelToken, fileProp = "file") => {
    try {
      const url = `${baseURL}/${path}`;
      const formData = new FormData();
      formData.append(fileProp, file);
      return await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          actionPermissions: user?.actionPermissions,
          clientId: user?.clientId,
          userId: user?.id,
        },
        withCredentials: true,
        cancelToken,
        ...config,
      });
    } catch (error) {
      if (error?.response?.status === 401) handleUnauthorized();
      else if (error?.response?.status === 416) showAuthorizationChangedDialog();
      throw error;
    }
  };

  const getDoc = async (path) => {
    try {
      const url = `${baseURL}/${path}`;
      return await axios.get(url, {
        headers: getHeaders({
          actionPermissions: user?.actionPermissions,
          clientId: user?.clientId,
          userId: user?.id,
        }),
        withCredentials: true,
        responseType: "arraybuffer",
      });
    } catch (error) {
      if (error?.response?.status === 401) handleUnauthorized();
      else if (error?.response?.status === 416) showAuthorizationChangedDialog();
      throw error;
    }
  };

  const httpClientValue = useMemo(
    () => ({ get, post, put, del, postDoc, getDoc }),
    [get, post, put, del, postDoc, getDoc]
  );

  return (
    <HTTPClientContext.Provider value={httpClientValue}>{children}</HTTPClientContext.Provider>
  );
}

export default HTTPClientProvider;
