import { useEffect } from "react";
import { useAuth } from "helper";
import { useNavigate, useLocation } from "react-router-dom";

const MENU_SECTIONS = ["administration"];
const MENU_ROUTES = ["/app/taxonomies", "/administration/companies"];

function AuthGuard({ children }) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const section = pathname.split("/")[1];
  const hasSectionPermission = () =>
    user?.actionPermissions
      ?.toLowerCase()
      .split(",")
      .filter((i) => i.includes("mnu"))
      .map((i) => i.substring(3))
      .includes(section);
  const hasRoutePermission = () =>
    user?.actionPermissions
      ?.toLowerCase()
      .split(",")
      .filter((i) => i.includes("mnu"))
      .map((i) => i.substring(3))
      .includes(pathname.split("/")[2]);

  useEffect(() => {
    if (user == null) {
      if (process.env.NODE_ENV === "development") navigate("/");
      else window.location = "https://iam-fe-test.xbrlpoint.com/?destinationRef=xbrl";
    } else if (
      (MENU_SECTIONS.includes(section) && !hasSectionPermission()) ||
      (MENU_ROUTES.includes(pathname) && !hasRoutePermission())
    ) {
      navigate("/app/projects");
    }
  }, [user, navigate]);

  return children;
}

export default AuthGuard;
