import { useHTTPClient } from "helper";

const ReportsService = () => {
  const { get, post, put, del, postDoc } = useHTTPClient();

  const getReports = (id) => get(`Documents/Download?ProjectShortGuid=${id}`);

  const getXhtml = (id) => get(`Documents/DownloadWithAdditionalData?ProjectShortGuid=${id}`);

  const getReport = () => get("Mappings");

  const createReport = (data) => post(`Reports`, data);

  const getProjects = (companyId) => get(`Dropdowns/CompanyProjects?CompanyShortGuid=${companyId}`);

  const getProjectReports = (projectId) =>
    get(`Dropdowns/ProjectReports?ProjectShortGuid=${projectId}`);

  const getReportMappings = (reportId) => get(`Reports/GetAllMappings/${reportId}`);

  const saveReportMappings = (data) => put(`Reports/UpdateMappings`, data);

  const deleteReport = (body) => del("Reports", body);

  const getAllReports = (shortGuid) => get(`Reports/GetAll/${shortGuid}`);

  const uploadExcel = (file, sheetIndex, config, sourceToken) =>
    postDoc(`Reports/UploadExcel?sheetIndex=${sheetIndex}`, file, config, sourceToken);

  return {
    getReports,
    getXhtml,
    getReport,
    createReport,
    getProjects,
    getProjectReports,
    getReportMappings,
    saveReportMappings,
    deleteReport,
    getAllReports,
    uploadExcel,
  };
};

export default ReportsService;
